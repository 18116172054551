.calculator__result__exp {
  font-size: 48px;
  line-height: 1;
  transform-origin: right bottom;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  color: var(--calc-res-color);
}

.calculator__result__exp:last-child {
  position: absolute;
  right: 0;
}

.calculator__result__exp span {
  display: inline-block;
  overflow: hidden;
  transition: width 0.3s ease;
}

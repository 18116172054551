* {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 34px;
  background-color: rgba(233, 233, 233, 1);
  border-radius: 5px;
  border: 1px solid rgba(42, 48, 102, 1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(42, 48, 102, 1);
  border-radius: 5px;
}
